import React, { Fragment } from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import Project from "../components/project";
import { StaticQuery, graphql } from 'gatsby';
import "../styles/index.scss";

const HomePage = () => {
    return(
        <Layout>
            <Head title="Home" />
            <StaticQuery 
                query={graphql`
                    query {
                        allProjects {
                            edges {
                                node {
                                    title
                                    description
                                    image_name
                                    site_url
                                    codebase_url
                                    codebase_icon
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <Fragment>
                        <div className="section">
                            <h2>About Me</h2>
                            <h3><em>Web developer with a passion for React, Gatsby, Node and Python.</em></h3>
                            <p>Client-side, I like to work with React.js because it's flexible and modular. Server-side, I prefer Django-Python when working with SQL databases and Node when working in NoSQL.</p>
                        </div>
                        <div className="section">
                            <h2 id="projects">Recent Projects</h2>
                            <div className="container">
                                {data.allProjects.edges.map(({ node }, idx) =>
                                    <Project
                                        key = {idx}
                                        title = {node.title}
                                        description = {node.description}
                                        imageName = {node.image_name}
                                        siteURL = {node.site_url}
                                        codeBaseURL = {node.codebase_url}
                                        codeBaseIcon = {node.codebase_icon}
                                    />
                                )}
                            </div>    
                        </div>
                        <div className="section">
                            <h2>Contact</h2>
                            <a id="email" href="mailto:thomas.dahlberg8@gmail.com">thomas.dahlberg8@gmail.com</a>
                        </div>
                    </Fragment>
                )}
            />
        </Layout>
    )
}

export default HomePage

