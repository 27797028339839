import React from "react";
import SVG from "../components/icon";

const Project = (props) => {
    return (
        <div className="panel">
            <h3>{props.title}</h3>
            <p>{props.description}</p>
            <img
                src={`./images/${props.imageName}`}
                alt={`${props.title} Screenshot`}
            />
            <div className="project-nav-container">
                {props.siteURL ? 
                    <a
                        className="nav-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={props.siteURL}
                    >Launch Site</a>
                    : null
                }
                {props.codeBaseURL ? 
                    <a 
                        className="icon-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={props.codeBaseURL}
                    >
                        {props.codeBaseIcon ?
                            <SVG name={props.codeBaseIcon} width={40} />
                            : <SVG name="default" width={40} />
                        }
                    </a>
                    : null
                }
            </div>
        </div>
    )
}

export default Project;
